<template>
    <div id="ap">
        <a-layout>
            <div class="content-header">
                <a-affix :offset-top="0" style="width: 100%;">
                    <a-row type="flex" justify="space-between">
                        <a-col span="12">
                            <common-page-btn @save="addRow"></common-page-btn>
                        </a-col>
                        <a-col span="12">
                            <a-row type="flex" align="middle" justify="end" :gutter="3">
                                <!-- <a-col span="6" v-if="getOrganId() == 0">
                                    <a-select v-model="organId" style="width:100%" @change="getList" placeholder="根据单位筛选">
                                        <a-select-option :value="organ.organId" v-for="(organ, index) in getOrganList()"
                                            :key="index">{{ organ.name }}</a-select-option>
                                    </a-select>
                                </a-col> -->
                            </a-row>
                        </a-col>
                    </a-row>
                </a-affix>
            </div>
        </a-layout>

        <a-layout>
            <a-layout-content>
                <a-table :scroll="{ x: '100%' }" :style="{ background: '#FFF' }" size="small" :columns="keys"
                         rowKey="categoryId" ref="list" :data-source="data" :bordered="false" :pagination="false">
                    <!--名称-->
                    <template slot-scope="text,row,index" slot="name">
                        <a-input v-model="row.name" v-if="row.edit" @pressEnter="confirmRow(row, index)"></a-input>
                        <span v-else>{{ row.name }}</span>
                    </template>
                    <!--排序-->
                    <template slot-scope="text,row,index" slot="sort">
                        <a-input-number v-model="row.sort" align="center" v-if="row.edit"
                                        @pressEnter="confirmRow(row, index)"></a-input-number>
                        <span v-else>{{ row.sort }}</span>
                    </template>
                    <!--操作-->
                    <template slot-scope="text,row,index" slot="action">
                        <a-button-group shape="circle" size="small">
                            <a-tooltip title="编辑" v-if="!row.edit">
                                <a-button icon="edit" @click="editRow(row, index)"></a-button>
                            </a-tooltip>
                            <a-tooltip title="保存" v-else>
                                <a-button icon="check" @click="confirmRow(row, index)"></a-button>
                            </a-tooltip>
                            <a-tooltip title="取消" v-if="row.edit"><a-button icon="close"
                                          @click="delRow(row, index)"></a-button></a-tooltip>
                            <a-tooltip title="删除" v-else><a-button icon="delete"
                                          @click="delRow(row, index)"></a-button></a-tooltip>
                        </a-button-group>
                    </template>
                </a-table>
            </a-layout-content>
        </a-layout>

    </div>
</template>

<script>
import utils from '@/common/utils';
import request from '@/config/request';
import apiUtil from '@/common/apiUtil';
export default {
    data() {
        return {
            loading: false,
            data: [],
            organId: this.getDefaultOrganId(),
            keys: [
                { title: 'ID', dataIndex: 'categoryId', width: 100, align: 'center' },
                { title: '名称', scopedSlots: { customRender: 'name' }, width: 260, ellipsis: true },
                { title: '排序', scopedSlots: { customRender: 'sort' }, align: 'center', width: 80, ellipsis: true, sorter: (a, b) => a.sort - b.sort },
                { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
            ]
        };
    },
    created() {
        this.getList();
    },
    methods: {
        ...apiUtil,
        addRow() {
            const item = {
                categoryId: '',
                name: '',
                organId: this.organId,
                sort: 0,
                edit: true
            };
            this.data.push(item);
        },
        delRow(row, index) {
            if (!row.categoryId) {
                this.data.splice(index, 1);
                return;
            }
            if (row.edit) {
                row.edit = false;
                this.data.splice(index, 1, row);
                return;
            }
            utils.confirm("您确认要删除此分类吗?").then(() => {
                utils.showSpin();
                request.delete("/platform/approve/category/del/" + row.categoryId).then(ret => {
                    this.data.splice(index, 1);
                });
            });
        },
        editRow(row, index) {
            row.edit = true;
            this.data.splice(index, 1, row);
        },
        confirmRow(row, index) {
            utils.showSpin();
            request.post("/platform/approve/category/save", row).then(ret => {
                this.getList();
            });
        },
        // 获取数据
        getList() {
            this.loading = true;
            this.http
                .get('/platform/approve/category', { organId: this.organId })
                .then(ret => {
                    this.data = ret.data;
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped></style>
